import React from "react";
import Lenis from "@studio-freight/lenis";
import gsap from "gsap";
import $ from "jquery";
import Arrowleft from "../assets/arrow-left-solid.svg";
import HeaderButton from "./contactbutton";
import ContactButton from "../components/maincontactbutton";
import NavContactButton from "./navcontactbutton";

class Header extends React.Component {
  headerGsap = () => {
    $(".home-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: homeLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function homeLink(url) {
      window.location.href = "/";
    }
    $(".process-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: processLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function processLink(url) {
      window.location.href = "/services";
    }
    $(".work-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: workLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function workLink(url) {
      window.location.href = "/work";
    }
    $(".contact-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: contactLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function contactLink(url) {
      window.location.href = "/contact";
    }
    $(".privacy-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: privacyLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function privacyLink(url) {
      window.location.href = "/privacy";
    }

    $(".blog-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: blogLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function blogLink(url) {
      window.location.href = "/blog";
    }
    $(".blog-temp-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: blogtempLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function blogtempLink(url) {
      window.location.href = "/blog-temp";
    }
    $(".sunnybee-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: sunnybeeLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function sunnybeeLink(url) {
      window.location.href = "/work/sunnybee";
    }
    $(".savittr-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: savittrLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function savittrLink(url) {
      window.location.href = "/work/savittr";
    }
    $(".dealsplus-website-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: dealspluswebsiteLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function dealspluswebsiteLink(url) {
      window.location.href = "/work/dealsplus-website";
    }
    $(".e-wallet-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: ewalletlink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function ewalletlink(url) {
      window.location.href = "/work/e-wallet";
    }
    $(".dealsplus-product-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: dealsplusproductLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function dealsplusproductLink(url) {
      window.location.href = "/work/dealsplus-product";
    }
    $(".fut-bank-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: futbankLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function futbankLink(url) {
      window.location.href = "/work/fut-bank";
    }
    $(".drapesanddreams-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: drapesanddreamsLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function drapesanddreamsLink(url) {
      window.location.href = "/work/drapesanddreams";
    }
    $(".authorcafe-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: authorcafeLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function authorcafeLink(url) {
      window.location.href = "/work/authorcafe";
    }

    $(".flyet-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: flyetLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function flyetLink(url) {
      window.location.href = "/work/flyet";
    }

    $(".crypto-wallet-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: cryptowalletLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function cryptowalletLink(url) {
      window.location.href = "/work/crypto-wallet";
    }

    $(".tnq-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: tnqLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function tnqLink(url) {
      window.location.href = "/work/tnq";
    }

    $(".eleveight-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: eleveightLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function eleveightLink(url) {
      window.location.href = "/work/eleveight";
    }

    $(".xtm-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: xtmLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function xtmLink(url) {
      window.location.href = "/work/xtm";
    }

    $(".healthcare-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: healthcareLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function healthcareLink(url) {
      window.location.href = "/work/healthcare";
    }

    $(".careers-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: careersLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function careersLink(url) {
      window.location.href = "/careers";
    }

    $(".careersuiux-link").on("click", function (e) {
      e.preventDefault();
      gsap.to(".home-cover-out", {
        duration: 0.1,
        opacity: 1,
        onComplete: careersuiuxLink,
        onCompleteParams: [$(this).attr("href")],
      });
    });
    function careersuiuxLink(url) {
      window.location.href = "/careers/ui-ux-designer";
    }
  };

  componentDidMount() {
    this.headerGsap();
  }

  render() {
    const lenis = new Lenis({
      duration: 1.5,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      direction: "vertical",
      gestureDirection: "vertical",
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return (
      <div>
        <header className="normal-header">
          <div className="container">
            <div className="row">
              <div className="col-4 header-left">
                <a className="home-link" href="/">
                  <img
                    alt="Fingerprints Future"
                    className="img-fluid"
                    src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/future-logo.svg"
                  />
                </a>
              </div>

              <div className="col-4 innr-btn-align text-center align-self-center">
                <a className="inner-page-btn work-link" href="/work">
                  <img src={Arrowleft} />
                  All Work
                </a>
              </div>

              <div className="col-5 header-right-parent">
                <div className="header-overlay"></div>
                <div className="header-right ">
                  <input
                    className="menu-icon"
                    type="checkbox"
                    id="menu-icon"
                    name="menu-icon"
                  />
                  <label className="" htmlFor="menu-icon">
                    <span style={{ opacity: "0" }}>Menu</span>
                  </label>
                  <nav className="container header-nav">
                    <div className="row">
                      <div className="col-md-6 padd-resp">
                        <ul>
                          <li id="menu-items">
                            <div id="menu-item" className="nav-line">
                              <a className="nav1 home-link" href="/">
                                Home
                              </a>
                            </div>
                            <div id="menu-item" className="nav-line">
                              <a className="nav2 process-link" href="/services">
                                Services
                              </a>
                            </div>
                            <div id="menu-item" className="nav-line">
                              <a className="nav3 work-link" href="/work">
                                Work
                              </a>
                            </div>
                            <div id="menu-item" className="nav-line">
                              <a className="nav3 blog-link" href="/blog">
                                Blog
                              </a>
                            </div>
                            <div id="menu-item" className="nav-line">
                              <a className="nav4" href="/contact">
                                <NavContactButton />
                              </a>
                              {/* <HeaderButton/> */}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 padd-resp">
                        <div className="head-addr">
                          <div className="head-addr-cont d-none d-lg-block d-md-block">
                            <h5>LOCATED AT</h5>
                            <h5>INDIA</h5>
                            <p className="head-addr-loc">
                              15/4, 3rd Floor, Haddows Lane,{" "}
                              <br className="d-block d-lg-none" />
                              Haddows Road, Nungambakkam, Chennai - 600006
                            </p>
                            <br />
                            <h5>AUSTRALIA</h5>
                            <p className="head-addr-loc">
                              6 Welford Circuit{" "}
                              <br className="d-block d-lg-none" />
                              North Kellyville NSW 2155
                            </p>
                            <a href="mailto:aus@fingerprintsfuture.com">
                              aus@fingerprintsfuture.com
                            </a>
                            <p className="head-addr-loc">
                              <a className="" href="tel:0490892685">
                                0490892685
                              </a>
                            </p>
                          </div>

                          <div className="head-addr-cont">
                            <h5 className="d-none d-lg-block">
                              FOR BUSINESS ENQUIRIES
                            </h5>
                            <p className="d-block d-lg-none d-md-none text-white">
                              INDIA
                            </p>
                            <p>
                              <a href="mailto:business@fingerprintsfuture.com">
                                business@fingerprintsfuture.com
                              </a>
                            </p>
                            {/* <p>
                                    <a className="head-addr-num" href="tel:+91 91761 11675">+91 91761 11675</a>
                                  </p> */}
                          </div>

                          <div className="d-block d-lg-none d-md-none head-addr-cont">
                            <p className="text-white">AUSTRALIA</p>
                            <p>
                              <a href="mailto:aus@fingerprintsfuture.com">
                                aus@fingerprintsfuture.com
                              </a>
                            </p>
                          </div>
                          <div className="head-addr-cont">
                            <h5 className="d-none d-lg-block">FOR CAREERS</h5>
                            <p className="d-block d-lg-none d-md-none text-white">
                              CAREERS
                            </p>
                            <p>
                              <a href="mailto:careers@fingerprintsfuture.com">
                                careers@fingerprintsfuture.com
                              </a>
                            </p>
                          </div>

                          <div className="head-addr-cont">
                            <a className="head-addr-social d-none" href="/#">
                              LinkedIn
                            </a>
                            <a className="head-addr-social d-none" href="/#">
                              Instagram
                            </a>
                            <a
                              className="head-addr-pp d-none privacy-link"
                              href="/privacy">
                              Privacy Policy
                            </a>
                          </div>

                          <div className="head-addr-cont d-none d-lg-block">
                            <a
                              className="head-addr-pp privacy-link"
                              href="/privacy">
                              Privacy Policy
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div className="d-none d-lg-block">
                    {/* <a href="/contact" className="button1 contact-link">Get in touch</a> */}
                    <HeaderButton />
                    {/* <a href="https://calendly.com/aishwaryadaswani" target="_blank" className="button1 schedule">Schedule a call</a> */}
                  </div>
                  <div className="d-none d-lg-none">
                    {/* <a href="/contact" className="button1 schedule contact-link">Get Started</a> */}
                    <HeaderButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
